<template>
  <div class="dashboard-toolbar-controls">
    <vs-dropdown vs-trigger-click>
      <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium">
        <span class="mr-2 leading-none">VEGA Reports</span>
        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
      </div>

      <vs-dropdown-menu>
        <vs-dropdown-item @click.prevent="onExportVisitorRatingBuyingIntent">
          <span class="flex items-center">
            <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
            <span>Export Visitor Rating Buying Intent</span>
          </span>
        </vs-dropdown-item>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { commonFunctions } from '@/mixins/commonFunctions'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
export default {
  mixins: [commonFunctions],
  props: ['vegaWidgetList'],

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      dateRange: 'dashboard/dateRange',
      vegaReports: 'dashboard/vegaReports',

      selectedAccount: 'selectedAccount'
    })
  },
  methods: {
    ...mapActions({
      updateVisitorsRatingBuyingIntent: 'dashboard/updateVisitorsRatingBuyingIntent'
    }),
    stopLoading() {
      setTimeout(() => {
        this.$vs.loading.close()
      }, 500)
    },
    getTimeInHHMMSS(totalSeconds) {
      return dayjs().startOf('day').add(totalSeconds, 's').format('HH:mm:ss')
    },

    onExportVisitorRatingBuyingIntent() {
      this.onGetVisitorsRatingAndIntentBQ()
    },
    formatTime(date) {
      return dayjs(date).format('HH:mm')
    },
    formatStringDate(date) {
      return dayjs(date).format('MMM D, YYYY HH:mm:ss')
    },

    generateCSVVisitorRatingBuyingIntent() {
      const headers = {
        intent: 'Buying Intent',
        total: 'Total',
        good: 'Good',
        neutral: 'Neutral',
        bad: 'Bad',
        support: 'Support'
      }
      const itemsFormatted = []

      this.vegaReports.visitorsRatingsBuyingIntent.forEach((item) => {
        itemsFormatted.push({
          intent: item.intent ? item.intent.replace(/,/g, '') : '',
          total: item.total,
          good: item.good,
          neutral: item.neutral,
          bad: item.bad,
          support: item.support
        })
      })

      const fileTitle = 'Visitors Rating and VEGA-Score'
      this.exportCSVFile(headers, itemsFormatted, fileTitle)
    },

    async onGetVisitorsRatingAndIntentBQ() {
      this.$vs.loading()

      const config = {
        queryFilter: ` dt.company="${this.selectedAccount.id}"`
      }

      config.queryFilter += ` AND dt.created BETWEEN "${dayjs
        .utc(this.dateRange.startDate)
        .startOf('day')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss UTC')}" AND "${dayjs.utc(this.dateRange.endDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss UTC')}"`

      if (this.vegaWidgetList && this.vegaWidgetList.length > 0) {
        if (this.vegaWidgetList.length === 1) {
          config.queryFilter += ` AND dt.dialogId="${this.vegaWidgetList[0].dialogId}"`
        } else {
          const dialogIds = this.vegaWidgetList.map((item) => `"${item.dialogId}"`).join(', ')
          config.queryFilter += `AND dt.dialogId in (${dialogIds})`
        }
      }

      config.queryFilter += ' AND dt.isVegaPopupDisplayed = true AND dt.scoreHighest IS NOT NULL'

      this.updateVisitorsRatingBuyingIntent([])

      try {
        const getVisitorsRatingAndIntentBQ = this.$functions.httpsCallable('getVisitorsRatingAndIntentBQ')

        const rs = await getVisitorsRatingAndIntentBQ({ config })
        const data = rs.data.res

        if (data && data.length > 0) {
          this.updateVisitorsRatingBuyingIntent(data)
          this.generateCSVVisitorRatingBuyingIntent()
        }
      } catch (err) {
        /* eslint-disable no-console */
        console.error(err.message)
      }
      this.stopLoading()
    }
  }
}
</script>
