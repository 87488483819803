var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-toolbar-controls" },
    [
      _c(
        "vs-dropdown",
        { attrs: { "vs-trigger-click": "" } },
        [
          _c(
            "div",
            {
              staticClass:
                "p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium",
            },
            [
              _c("span", { staticClass: "mr-2 leading-none" }, [
                _vm._v("VEGA Reports"),
              ]),
              _c("feather-icon", {
                attrs: { icon: "ChevronDownIcon", svgClasses: "h-4 w-4" },
              }),
            ],
            1
          ),
          _c(
            "vs-dropdown-menu",
            [
              _c(
                "vs-dropdown-item",
                {
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onExportVisitorRatingBuyingIntent.apply(
                        null,
                        arguments
                      )
                    },
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "flex items-center" },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "SaveIcon", svgClasses: "h-4 w-4" },
                      }),
                      _c("span", [
                        _vm._v("Export Visitor Rating Buying Intent"),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }